/** Funciones utiles relacionadas con la subida de ficheros */

import { getContentCms, uploadFromGoogleDrive } from '../crud/contents.crud';
import { getTicketUploadContent, getTicketUploadThumbnail, GET_PROJECTS, GET_PROJECTS_COLLECTION, GET_PROJECTS_PUBLICATION } from '../crud/projects.crud';
import { getTypeFromMime } from '../store/contents.duck';
import { createContentManager } from './contentCms';
import { hasData } from './validation';

/**
 * Realiza una suba de un fichero
 * @param {*} file Fichero elegido por el usuario
 * @param {*} token Este parametro generalmente nos lo da el ticket que se pide previamente
 * @param {*} endpoint Este parametro generalmente nos lo da el ticket que se pide previamente
 * @param {*} onProgressChange Funcion que se invocara actualizando el porcentaje de subida
 * @param {*} fileName Nombre del fichero opcional sino va a coger file.name
 * @param {*} baseUrl Es la url que le pondrá al principio al endpoint para realizar la llamada.
 *                    Hay veces que para la base podemos querer hacer esta logica:
 *                    const baseUrlFinal = isModeProduction() ? `${window.location.origin}` : `${baseUrl}`;
 * @param {*} fileType Tipo de archivo que queremos subir puede ser 'file' o 'blob'
 */
export const sendRequest = (file, token, endpoint, onProgressChange, fileName, baseUrl, fileType = 'file') => {
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();
    req.upload.addEventListener('progress', (event) => {
      const percentage = (event.loaded / event.total) * 100;
      onProgressChange && onProgressChange(parseInt(percentage));
    });
    req.upload.addEventListener('load', (event) => {});
    req.upload.addEventListener('error', (event) => {
      reject(req.response);
    });
    req.upload.addEventListener('abort', (event) => {
      reject(req.response);
    });
    req.onreadystatechange = () => {
      if (req.status) {
        if (req.status === 200) {
          resolve(req.response);
        } else {
          reject(req.response);
        }
      }
    };
    const formData = new FormData();
    const filename = fileName ? fileName : file.name;
    if (fileType === 'file') formData.append('file', file, filename);
    if (fileType === 'blob') formData.append('blob', file);
    formData.append('token', token);
    const endpointFinal = `${baseUrl}${endpoint}`;
    req.open('POST', endpointFinal);
    req.onreadystatechange = function (oEvent) {
      if (req.readyState === 4) {
        if (req.status === 200) {
          resolve(req);
        } else {
          reject(req);
        }
      }
    };
    req.send(formData);
  });
};

/**
 * ACTION UPLOAD THUMBNAIL MODE 1
 * ------------------------------
 * Realiza el proceso completo de subir un fichero y cuando termina se lo asigna al campo thumbnail del content
 * Llamar a este metodo despues de que el usuario haya elegido un fichero
 * Dentro de este metodo va a pedir un ticket y con los parametros recibidos
 * va a realizar una segunda llamada con la subida del fichero.
 * Una vez que termine esta segunda llamada internamente va a poner en url de la imagen en el campo thumbnail
 * @param {*} file Fichero elegido por el usuario
 * @param {*} contentGuid Content guid
 * @param {*} fileName Nombre del fichero opcional sino va a coger file.name
 * @param {*} baseUrl  Es la url que le pondrá al principio al endpoint para realizar la llamada.
 *                     Hay veces que para la base podemos querer hacer esta logica:
 *                     const baseUrlFinal = isModeProduction() ? `${window.location.origin}` : `${baseUrl}`;
 * @param {*} onProgressChange Funcion que se invocara actualizando el porcentaje de subida
 * @param {*} onSucess Funcion que se invocara cuando se termine el proceso satisfactoriamente
 * @param {*} onError Funcion que se invocara si hay un error en el proceso
 */
export function actionUploadThumbnailMode1(file, guid, fileName, baseUrl, onProgressChange, onSucess, onError, type) {
  const typeThumbnail = getTypeThumbnail(type);

  getTicketUploadThumbnail(guid, typeThumbnail)
    .then((response) => {
      if (response.data && response.data.data && response.data.data.data) {
        const upload = response.data.data.data.upload;
        if (upload) {
          sendRequest(file, upload.token, upload.endpoint, onProgressChange, fileName, baseUrl)
            .then((values) => {
              onSucess && onSucess();
            })
            .catch((reason) => {
              console.log(reason);
              onError && onError();
              alert('Warning! error when upload file');
            });
        }
      }
    })
    .catch((err) => {
      console.log(err);
      onError && onError();
    });
}

/**
 * ACTION UPLOAD THUMBNAIL MODE 2
 * ------------------------------
 * Realiza el proceso completo de subir un fichero y cuando termina invoca a onSucess con los parametros onSucess(contentNewImage.guid, urlImage)
 * El que llame a este metodo será el responsable de asignar dicha imagen al campo thumbnail del Content
 * Llamar a este metodo despues de que el usuario haya elegido un fichero
 * Dentro de este metodo se va crear un contenido de tipo imagen, despues se  va a pedir un ticket y con los parametros recibidos
 * va a realizar otra llamada con la subida del fichero.
 * Una vez que termine esta ultima llamada se va a invocar al metodo onSucess devolviendo:  onSucess(contentNewImage.guid, urlImage)
 * @param {*} file Fichero elegido por el usuario
 * @param {*} fileName Nombre del fichero opcional sino va a coger file.name
 * @param {*} baseUrl  Es la url que le pondrá al principio al endpoint para realizar la llamada.
 *                     Hay veces que para la base podemos querer hacer esta logica:
 *                     const baseUrlFinal = isModeProduction() ? `${window.location.origin}` : `${baseUrl}`;
 * @param {*} onProgressChange Funcion que se invocara actualizando el porcentaje de subida
 * @param {*} onSucess Funcion que se invocara cuando se termine el proceso satisfactoriamente
 * @param {*} onError Funcion que se invocara si hay un error en el proceso
 */
export function actionUploadThumbnailMode2(file, fileName, baseUrl, onProgressChange, onSucess, onError) {
  const filename = fileName ? fileName : file.name;
  const name = filename;
  const mimeType = file.type;
  createContentManager.createImage(
    name,
    null,
    mimeType,
    (responseCreateImage) => {
      if (responseCreateImage && responseCreateImage.guid) {
        // Sucess
        const contentNewImage = responseCreateImage;
        getTicketUploadContent(contentNewImage.guid, false, null)
          .then((responseTicket) => {
            if (responseTicket.data && responseTicket.data.data && responseTicket.data.data.data) {
              const upload = responseTicket.data.data.data.upload;
              if (upload) {
                sendRequest(file, upload.token, upload.endpoint, onProgressChange, fileName, baseUrl)
                  .then((responseSendRequest) => {
                    getContentCms(contentNewImage.guid)
                      .then((responseGetContent) => {
                        const { data } = responseGetContent;
                        if (data && data.status === 'success' && data.data && hasData(data.data.url)) {
                          const urlImage = data.data.url;
                          onSucess && onSucess(contentNewImage.guid, urlImage);
                        } else {
                          onError && onError('Error');
                        }
                      })
                      .catch((err) => {
                        console.log(err);
                        onError && onError('Error');
                      });
                  })
                  .catch((reason) => {
                    console.log(reason);
                    onError && onError('Error');
                    alert('Warning! error when upload file');
                  });
              }
            } else onError && onError('Error');
          })
          .catch((err) => {
            console.log(err);
            onError && onError('Error');
          });
      } else onError && onError('Error');
    },
    () => {
      // == Error
    }
  );
}

/**
 * ACTION UPLOAD THUMBNAIL MODE 2
 * ------------------------------
 * Realiza el proceso completo de subir un fichero y cuando termina invoca a onSucess con los parametros onSucess(contentNewMultimedia.guid, urlImage, name, description, mimeType, typeContentGuid, typeContentName, thumbnail)
 * El que llame a este metodo será el responsable de asignar dicha imagen al campo thumbnail del Content
 * Llamar a este metodo despues de que el usuario haya elegido un fichero
 * Dentro de este metodo se va crear un contenido de tipo imagen, despues se  va a pedir un ticket y con los parametros recibidos
 * va a realizar otra llamada con la subida del fichero.
 * Una vez que termine esta ultima llamada se va a invocar al metodo onSucess devolviendo:  onSucess(contentNewMultimedia.guid, urlImage, name, description, mimeType, typeContentGuid, typeContentName, thumbnail)
 * @param {*} file Fichero elegido por el usuario
 * @param {*} fileName Nombre del fichero opcional sino va a coger file.name
 * @param {*} baseUrl  Es la url que le pondrá al principio al endpoint para realizar la llamada.
 *                     Hay veces que para la base podemos querer hacer esta logica:
 *                     const baseUrlFinal = isModeProduction() ? `${window.location.origin}` : `${baseUrl}`;
 * @param {Function} onBeginsUpload Funcion que se invoca una una vez cuando comienza la subida del fichero
 * @param {*} onProgressChange Funcion que se invocara actualizando el porcentaje de subida
 * @param {*} onSucess Funcion que se invocara cuando se termine el proceso satisfactoriamente
 * @param {*} onError Funcion que se invocara si hay un error en el proceso
 */
export function actionUploadMultimedia(file, fileName, baseUrl, onBeginsUpload, onProgressChange, onSucess, onError) {
  const filename = fileName ? fileName : file.name;
  const name = filename;
  const mimeType = file.type;
  const typeFromMime = getTypeFromMime(mimeType);
  createContentManager.createContentMultimedia(
    name,
    null,
    typeFromMime,
    mimeType,
    (responseCreateImage) => {
      if (responseCreateImage && responseCreateImage.guid) {
        // Sucess
        const contentNewMultimedia = responseCreateImage;
        getTicketUploadContent(contentNewMultimedia.guid, false, null)
          .then((responseTicket) => {
            if (responseTicket.data && responseTicket.data.data && responseTicket.data.data.data) {
              const upload = responseTicket.data.data.data.upload;
              if (upload) {
                onBeginsUpload && onBeginsUpload(contentNewMultimedia.guid, name, mimeType, typeFromMime);
                sendRequest(
                  file,
                  upload.token,
                  upload.endpoint,
                  (progress) => onProgressChange && onProgressChange(progress, contentNewMultimedia.guid, name, mimeType, typeFromMime),
                  fileName,
                  baseUrl
                )
                  .then((responseSendRequest) => {
                    getContentCms(contentNewMultimedia.guid)
                      .then((responseGetContent) => {
                        const { data } = responseGetContent;
                        if (data && data.status === 'success' && data.data && hasData(data.data.url)) {
                          const urlImage = data.data.url;
                          const description = data.data.description;
                          const thumbnail = data.data.thumbnail;
                          const typeContentGuid = hasData(data.data.type_guid) ? data.data.type_guid : typeFromMime;
                          const typeContentName = data.data.type_name;
                          onSucess && onSucess(contentNewMultimedia.guid, urlImage, name, description, mimeType, typeContentGuid, typeContentName, thumbnail);
                        } else {
                          onError && onError('Error');
                        }
                      })
                      .catch((err) => {
                        console.log(err);
                        onError && onError('Error');
                      });
                  })
                  .catch((reason) => {
                    console.log(reason);
                    onError && onError('Error');
                    alert('Warning! error when upload file');
                  });
              }
            } else onError && onError('Error');
          })
          .catch((err) => {
            console.log(err);
            onError && onError('Error');
          });
      } else onError && onError('Error');
    },
    () => {
      // == Error
    }
  );
}

function getTypeThumbnail(type) {
  switch (type) {
    case 'publication':
      return GET_PROJECTS_PUBLICATION;
    case 'collection':
      return GET_PROJECTS_COLLECTION;

    default:
      return GET_PROJECTS;
  }
}

export function getExtension(file) {
  return file && file.split('.').pop();
}

export function allowedUpload(typeFile, nameFile = '', cms_types = []) {
  const extension = String(getExtension(nameFile)).toLowerCase();
  let types = getTypeFormat(typeFile, cms_types, extension);

  if (extension === 'pdf') {
    //there are many formats of content guid that allows PDF extension
    types = types.filter((element) => element.guid === 'CTTY_05');
  }
  if (extension === 'zip') {
    //there are many formats of content guid that allows ZIP extension - fix for GENIALLY html contents
    types = types.filter((element) => element.guid === 'CTTY_08');
  }
  if (extension === 'doc' || extension === 'docx') {
    //there are many formats of content guid that allows OFFICE extension
    types = types.filter((element) => element.guid === 'CTTY_12');
  }
  if (typeFile && types.length > 0) {
    return types[0];
  }
  return [];
}

export function getTypeFormat(type_guid, types = [], extension) {
  if (types && types.length > 0) {
    const formats = types.filter((element) => element.guid === type_guid);
    if (formats.length) return formats;
  }
  //verify formats array inside types
  if (types && types.length > 0) {
    let formats = [];
    types.forEach((element) => {
      element.formats.forEach((format) => {
        if (format.format == type_guid || format['mime-type'] == type_guid) formats.push(element);
      });
    });
    return formats.length ? formats : [];
  }
  return [];
}
export function alloweSizeUpload(size, role_guid = 'R02') {
  const fileMaxSizeStudent = 25;
  const fileMaxSizeTeacher = 200;
  const sizeAllowed = role_guid == 'R01' ? fileMaxSizeStudent : fileMaxSizeTeacher;
  return size < sizeAllowed * 1024 * 1024;
}

/**
 * ACTION UPLOAD MULTIMEDIA
 * ------------------------------
 * Realiza el proceso completo de subir un fichero para multimedia de google drive
 */
export function actionUploadMultimediaGoogleDrive(fileId, fileName, fileMime, oauthToken, onSucess, onError) {
  const name = fileName;
  const mimeType = fileMime;
  const typeFromMime = getTypeFromMime(mimeType);
  createContentManager.createContentMultimedia(
    name,
    null,
    typeFromMime,
    mimeType,
    (responseCreateImage) => {
      if (responseCreateImage && responseCreateImage.guid) {
        // Sucess
        const contentNewMultimedia = responseCreateImage;
        _uploadFromGoogleDrive(
          contentNewMultimedia.guid,
          fileId,
          'assets',
          oauthToken,
          (fileUrl, fileSize) => {
            getContentCms(contentNewMultimedia.guid)
              .then((responseGetContent) => {
                const { data } = responseGetContent;
                if (data && data.status === 'success' && data.data && hasData(fileUrl)) {
                  const urlImage = fileUrl;
                  const description = data.data.description;
                  const thumbnail = data.data.thumbnail;
                  const typeContentGuid = hasData(data.data.type_guid) ? data.data.type_guid : typeFromMime;
                  const typeContentName = data.data.type_name;
                  onSucess && onSucess(contentNewMultimedia.guid, urlImage, name, description, mimeType, typeContentGuid, typeContentName, thumbnail);
                } else {
                  onError && onError('Error');
                }
              })
              .catch((err) => {
                console.log(err);
                onError && onError('Error');
              });
          },
          onError
        );
      } else onError && onError('Error');
    },
    () => {
      // == Error
    }
  );
}

/**
 * UPLOAD FROM GOOGLE DRIVE
 * ------------------------------
 * Realiza el proceso completo de subir un fichero y cuando termina invoca a onSucess con los parametros onSucess(contentNewImage.guid, urlImage)
 * @param {*} contentGuid
 * @param {*} fileId
 * @param {string} innerFolder Indica la carpeta donde se creara internamente, es un parametro opcional
 * @param {*} oauthToken
 * @param {*} onSucess
 * @param {*} onError
 */
function _uploadFromGoogleDrive(contentGuid, fileId, innerFolder = null, oauthToken, onSucess, onError) {
  uploadFromGoogleDrive(contentGuid, fileId, oauthToken, innerFolder)
    .then((response) => {
      _processUploadFromGoogle(response, onSucess, onError);
    })
    .catch((err) => {
      console.log(err);
      onError && onError('Error');
    });
}

function _processUploadFromGoogle(response, onSucess, onError) {
  const { data } = response;
  if (data && data.status === 'success' && data.data && data.data.bundle && data.data.uploaded_files) {
    const { bundle } = data.data;
    const urlBundle = bundle.encoded_url || bundle.url;
    const sizeBundle = bundle.size;
    const files = data.data.uploaded_files;
    const fileFound = files.find((f) => (f.encoded_url || f.url) === urlBundle);
    if (fileFound) {
      const fileUrl = fileFound.encoded_url || fileFound.url;
      onSucess && onSucess(fileUrl, sizeBundle);
    } else {
      onError && onError('Error');
    }
  } else {
    onError && onError('Error');
  }
}
