import i18next from 'i18next';
import React from 'react';
import BubbleContent from '_core/modules/atoms/BubbleContent';
import { stripHtml } from './utils';

export function isTabOneTool(currentTab) {
  return currentTab === 'cronometer' || currentTab === 'countdown' || currentTab === 'turn-of-speak';
}

function parsePost(post) {
  let parsePost = post.data ? post.data : post;
  let parseItem = null;
  let text = parsePost.name;
  if (parsePost?.items[0]?.data?.type === 'text' || parsePost?.items[0]?.type === 'text') {
    parseItem = parsePost.items[0].data ? parsePost.items[0].data : parsePost.items[0];

    text = parseItem.items[0].heading || parseItem.items[0].paragraph;
  }
  return text;
}

export function parseActivityName(item) {
  return stripHtml(item?.items?.length > 0 && item.items[0].data?.data ? item.items[0].data.data.stimulus : item.items[0].data.stimulus);
}

export function parseActivities(items, backgroundColor) {
  let newActivities = [];
  items.forEach((element) => {
    newActivities = [...newActivities, ...element.items.filter((item) => item.data?.type === 'lemonade' || item.type === 'lemonade')];
  });

  return newActivities
    .filter((item) => item.items?.length === 1)
    .map((item, index) => {
      return {
        ...item,
        guid: item.data?.id ? item.data?.id : item.id,
        customIcon: <BubbleContent number={index + 1} backgroundColor={backgroundColor} size="s" />,
        text: parseActivityName(item),
        type_name: i18next.t('assigments:Activity'),
        index: index + 1,
      };
    });
}

export function parseItemLesson(selectedLesson) {
  let itemsAnchors = [];
  if (selectedLesson?.guid) {
    itemsAnchors = selectedLesson.posts.map((post, index) => ({
      type_name: i18next.t('lesson:Content'),
      type: 'mint',
      icon: 'file-text',
      text: stripHtml(parsePost(post)),
      guid: post.guid,
    }));
  }

  return itemsAnchors;
}
export function parseItemsProjectionMint(lesson, backgroundColor) {
  return lesson ? [...parseItemLesson(lesson), ...parseActivities(lesson.posts, backgroundColor)] : [];
}
