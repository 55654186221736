const BASE_DEV = 'https://tangerine-dev1.oneclicklabs.es/';
// const BASE_DEV = 'https://tangerine-qa1.oneclicklabs.es/';
// const BASE_DEV = 'https://tangerine-latest.oneclick.es/';

const BASE_AGNOSTIC = window.location.origin.includes('localhost') ? BASE_DEV : window.location.origin + '/';

export const defaultConfig = {
  BASE_URL_: BASE_AGNOSTIC,
  BASE_URL_GAMIFICATION: BASE_AGNOSTIC,
  API_URL: BASE_AGNOSTIC + 'api/',
  BASE_STORAGE_URL: BASE_AGNOSTIC + 'files-storage-tools/',
};
