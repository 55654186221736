import { put, takeLatest } from 'redux-saga/effects';
import { defaultConfig } from '../../config/environment';
import { getApiHealth } from '../../crud/appInfo.crud';
import { isInPublisher } from '../../utils/utils';
import { REDUCER_APPINFO } from '../conf';

export const actionTypes = {
  GetApiHealth: 'appInfo/GET_API_HEALTH',
  SaveApiHealth: 'appInfo/SAVE_API_HEALTH',
};

const initialState = {
  apiHealth: {},
  info: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetApiHealth:
      return { ...state };

    case actionTypes.SaveApiHealth: {
      let data = {};
      if (action.payload.value.data && action.payload.value.data.platform_health_status) {
        try {
          data = JSON.parse(action.payload.value.data.platform_health_status);

          data = isInPublisher(defaultConfig) && data.publisher ? data.publisher : data.frontoffice ? data.frontoffice : data;
          console.log('apiHealth data', data);
        } catch (error) {
          console.log(error, 'appinfo.duck');
        }
      }
      return { ...state, apiHealth: data };
    }

    default:
      return state;
  }
};

export const selectors = {
  apiHealth: (state) => {
    return state[REDUCER_APPINFO] && state[REDUCER_APPINFO].apiHealth;
  },
};

export const actions = {
  getApiHealth: (value) => ({ type: actionTypes.GetApiHealth, payload: { value } }),
  saveApiHealth: (value) => ({ type: actionTypes.SaveApiHealth, payload: { value } }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetApiHealth, function* getApiHealthSaga(action) {
    const { data } = yield getApiHealth(action.payload);
    yield put(actions.saveApiHealth(data));
  });
}
