import { defaultConfig } from 'app/config/environment';
import axios from 'axios';

export const QUESTIONS = 'front/v2/questions?type[]=CTTY_14'; //The param type is hardcoded because we always wants question type lemonade
export const QUESTIONS_SOURCES = 'cms/questions-sources';
export const QUESTIONS_YEARS = 'cms/questions-years';
export const QUESTIONS_EDUCATION_FILTERS = 'front/v2/questions-education-filters';
export const QUESTIONS_TYPES = 'cms/questions-types';

export const IA_API_KEY = '057d3a1e-c8da-4582-ae1c-9106cc8320d8';
export const IA_URL_ENDPOINT = 'https://api.app-ai.oneclicklabs.es';
export const QUESTIONS_NEW_IA = '/tangerine-activities';
export const EXPERIMENTS = '/experiments/openai';

export function getQuestions(params) {
  return axios.get(QUESTIONS, { params: params });
}

export function getQuestionsSources() {
  return axios.get(QUESTIONS_SOURCES);
}

export function getQuestionsYears() {
  return axios.get(QUESTIONS_YEARS);
}

export function getQuestionsEducationFilters() {
  return axios.get(QUESTIONS_EDUCATION_FILTERS);
}

export function getQuestionsTypes() {
  return axios.get(QUESTIONS_TYPES);
}

export function getQuestionFroalaUploadTicket(contentGuid) {
  return axios.get(`cms/contents/${contentGuid}/content/upload?uploader=froala`, {
    headers: {
      'app-referer': defaultConfig.API_URL,
    },
  });
}

export function generateNewQuestionWithIA(data) {
  return axios.post(`${IA_URL_ENDPOINT}${QUESTIONS_NEW_IA}`, data);
}

// Enpoint to generate a student feedback with IA
export function generateFeedback(data) {
  return axios.post(`${IA_URL_ENDPOINT}${EXPERIMENTS}`, data);
}
