import * as DLO from './DLO.duck';
import * as _collections from './_collections.duck';
import * as asset from './asset.duck';
import * as auth from './auth.duck';
import * as calendars from './calendar.duck';
import * as collections from './collections.duck';
import * as conf from './conf';
import * as contentTypes from './contentTypes.duck';
import * as contents from './contents.duck';
import * as contentsBooks from './contentsBooks.duck';
import * as countries from './countries.duck';
import * as courses from './courses.duck';
import * as customTags from './customTags.duck';
import * as demo from './demo.duck';
import * as educationLevels from './educationLevels.duck';
import * as gamification from './gamification.duck';
import * as groups from './groups.duck';
import * as history from './history.duck';
import * as i18n from './i18n';
import * as langs from './langs.duck';
import * as learningObjectives from './learningObjectives.duck';
import * as lemonadeTest from './lemonadeTest.duck';
import * as lessons from './lessons.duck';
import * as notifications from './notifications.duck';
import * as organization from './organization.duck';
import * as programs from './programs.duck';
import * as projection from './projection.duck';
import * as projectsLevels from './projectsLevels.duck';
import * as schoolYears from './schoolYears.duck';
import * as tasks from './tasks.duck';
import * as templates from './templates.duck';
import * as topics from './topics.duck';
import * as types from './types.duck';
import * as ui from './ui.duck';

import * as aiAssistant from './aiAssistant.duck';

//Export reducer duck
export {
  DLO,
  _collections,
  aiAssistant,
  asset,
  auth,
  calendars,
  collections,
  conf,
  contentTypes,
  contents,
  contentsBooks,
  countries,
  courses,
  customTags,
  demo,
  educationLevels,
  gamification,
  groups,
  history,
  i18n,
  langs,
  learningObjectives,
  lemonadeTest,
  lessons,
  notifications,
  organization,
  programs,
  projection,
  projectsLevels,
  schoolYears,
  tasks,
  templates,
  topics,
  types,
  ui,
};
