import axios from "axios";

export function requestWithCancel(url, conf, type='get'){
    let source = axios.CancelToken.source();
    if (conf)
      conf.cancelToken = source.token;
    return {
      source: source, 
      request: type == 'post' ? axios.post(url, conf) : axios.get(url, conf)
    };
}

export function requestPostWithCancel(url, params){
    let source = axios.CancelToken.source();        
    return {
      source: source, 
      request: axios.post(url, params, {cancelToken: source.token})
    };
}

export function randomGuidGenerator() {
  var S4 = function() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4();
}