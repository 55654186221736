import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

const PdfViewerFullScreen = ({ url, onDocumentLoadSuccess }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div className="pdf-viewer-fullscreen">
      <Document className="pdf-viewer-fullscreen__document" file={url} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page className="pdf-viewer-fullscreen__page" pageNumber={index + 1} key={`page_${index + 1}`} />
        ))}
      </Document>
    </div>
  );
};

export default PdfViewerFullScreen;
