import PropTypes from 'prop-types';
import React from 'react';
import { ReactSVG } from 'react-svg';

const Icon = ({ type, title, size, color, className }) => {
  const svgContextCore = require.context('_core/assets/icons', false, /\.svg$/);
  const svgContextApp = require.context('app/assets/icons', false, /\.svg$/);
  let libComponent = 'core';
  let src = svgContextCore(`./close.svg`);

  if (hasIconApp()) {
    libComponent = 'skin';
    src = svgContextApp(`./${type}.svg`);
  } else if (hasIcon()) {
    libComponent = 'core';
    src = svgContextCore(`./${type}.svg`);
  }

  function hasIconApp() {
    return svgContextApp.keys().find((item) => item === `./${type}.svg`);
  }
  function hasIcon() {
    return svgContextCore.keys().find((item) => item === `./${type}.svg`);
  }

  return (
    <ReactSVG
      src={src}
      title={title}
      beforeInjection={(svg) => {
        svg.classList.add('icon');
        svg.classList.add(`icon--lib-${libComponent}`);
        if (size) svg.classList.add(`icon--size-${size}`);
        if (color) svg.classList.add(`icon--color-${color}`);
      }}
    />
  );
};

Icon.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.oneOf(['xs', 's', 'default', 'big', 'big-2', 'xl', 'xxl']),
  color: PropTypes.oneOf([
    '',
    ' ',
    'default',
    'gradient',
    'primary',
    'secondary',
    'white',
    'blue',
    'blue2',
    'black',
    'light',
    'success',
    'red',
    'yellow',
    'label',
    'green',
    'brown',
    'original',
  ]),
};

Icon.defaultProps = {
  type: 'search',
  title: '',
  size: 'default',
  color: 'default',
};

export default Icon;
