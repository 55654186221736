import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const BubbleContent = ({ number, className, status, onClick, disabled, size, backgroundColor, color }) => {
  return (
    <button
      className={clsx('bubble-content', className, `bubble-content--${status}`, `bubble-content--size-${size}`, `bubble-content--color-${color}`, {
        'bubble-content--disabled': disabled,
      })}
      onClick={onClick}
      disabled={disabled}
      style={{ backgroundColor: backgroundColor }}
    >
      <div className="bubble-content__content">{number}</div>
    </button>
  );
};

BubbleContent.propTypes = {
  number: PropTypes.oneOfType([PropTypes.number, PropTypes.element]),
  status: PropTypes.oneOf(['default', 'fill', 'bordered', 'current']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['s', 'default', 'm', 'xl']),
  color: PropTypes.oneOf(['default', 'white', 'primary', 'fill-white', 'gray', 'green', 'dark']),
};

BubbleContent.defaultProps = {
  number: 1,
  status: 'default',
  onClick: function () {},
  disabled: false,
  size: 'default',
  color: 'default',
};

export default BubbleContent;
